<template>
    <el-dialog
      :modal="modal"
      :close-on-click-modal="false"
      append-to-body
      top="10vh"
      :title="dialogTitle"
      :visible.sync="showDialog"
      :custom-class="customClass"
      :fullscreen="fullscreen"
      @close="closeDialog('cancel')"
      :width="width"
      class="commonDialog"
      :style="{'--maxHeight':maxHeight}"
    >
    <div class="positionabs" v-if="showFullscreen">
        <i class="el-icon-full-screen" @click="fullClick()"></i>
      </div>
      <slot></slot>
      <span slot="footer" class="dialog-footer" v-if="showBtn">
        <el-button v-if="showCopyBtn" @click="closeDialog('copy')">复制</el-button>
        <el-button v-if="showCancelBtn" @click="closeDialog('cancel')">取消</el-button>
        <el-button
          v-if="showConfirmBtn"
          type="primary"
          @click="closeDialog('confirm')"
        >确定</el-button>
      </span>
    </el-dialog>
</template>
  <script>
export default {
  props: {
    //弹框标题
    dialogTitle: {
      type: String,
      default: "",
    },
    //弹框宽度
    width: {
      type: String,
      default: "70%",
    },
    //设置弹框内容最大显示高度
    maxHeight:{
      type: String,
      default: "70vh",
    },
    //弹框自定义类名
    customClass:{
        type: String,
        default: ''
    },
    //是否显示按钮
    showBtn: {
      type: Boolean,
      default: true,
    },
    //是否显示复制按钮
    showCopyBtn: {
      type: Boolean,
      default: false,
    },
    //是否显示取消按钮
    showCancelBtn: {
      type: Boolean,
      default: true,
    },
     //是否显示确定按钮
    showConfirmBtn: {
      type: Boolean,
      default: true,
    },
    //遮罩层
    modal:{
      type: Boolean,
      default: true,
    },
    //全屏按钮
    showFullscreen: {
        type: Boolean,
        default: false
    },
  },
  components: {},
  computed: {},
  data() {
    return {
      showDialog: true,
      fullscreen: false,
    };
  },
  methods: {
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    closeDialog(type) {
      if(type == 'cancel') this.showDialog = false;
      this.$emit(type);
    },
  },
};
</script>
<style scoped lang="scss">
.commonDialog ::v-deep .el-dialog__header {
  padding: 13px 20px !important;
}

.commonDialog ::v-deep .el-dialog__header .el-dialog__headerbtn {
  top: 11px !important;
  font-size: 20px;
}

.commonDialog ::v-deep .el-dialog__body{
    padding: 15px 20px !important;
    max-height: var(--maxHeight);
    overflow-y: auto;
}

.commonDialog ::v-deep .el-dialog__footer{
  padding: 10px 20px 17px;
  border-top: 1px solid #D9D9D9;
}
</style>
